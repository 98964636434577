import { render, staticRenderFns } from "./client-view-toolbar.vue?vue&type=template&id=4286e5d8&scoped=true&"
import script from "./client-view-toolbar.vue?vue&type=script&lang=js&"
export * from "./client-view-toolbar.vue?vue&type=script&lang=js&"
import style0 from "./client-view-toolbar.vue?vue&type=style&index=0&id=4286e5d8&lang=scss&scoped=true&"
import style1 from "./client-view-toolbar.vue?vue&type=style&index=1&id=4286e5d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4286e5d8",
  null
  
)

export default component.exports
import {QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn})
